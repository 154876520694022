@import './theme.scss';

.table {
	border:1px solid $base200;;
	background:white;
	border-collapse: collapse;
	margin-bottom:30px;
	width:100%;
	thead {
		background-color:$base100;;
		color:$base;
	}
	td,th {
		border-top:1px solid $base200;;
		padding:12px;
		text-align: left;
	}
	&.pricing {
		tr {
			td,th {
				font-size:14px;
				padding-left:10px;
				b {
					font-size:18px;
				}
			}
		}
	}
}

.tax-invoice {
	margin-top:30px;
	background: white;
	border:1px solid rgba($base, 0.25);
	padding:45px;;
	position: relative;
	box-shadow: 0px 8px 8px 0px rgba(0,0,0,0.15);

	h2 {
		color:$base;
		margin-bottom:4px;
	}
	h3 {
		margin-top:0;
		margin-bottom:0;
		font-size:24px;
	}

	p {
		font-size:13px;
		color:$base300;
		b {
			font-size:14px;
			display:block;
			color:$base400;
			margin-bottom:10px;
		}
	}

	i {
		margin-right:5px;
		color:$base300;
		display:none;
	}
	img {
		margin-bottom: 20px;
	}
	.details {
		margin-bottom:30px;
		float:right;
		text-align: right;
		font-size:14px;
	}
	.business-name {
		font-size:24px;
		color:$base;
		margin-bottom:4px;
	}
	.abn {
		margin-bottom:5px;
	}

	.to-whom {
		margin-top:30px;
		b {
			color:$base;
		}
	}



	table {
		text-align: left;
		border-spacing: 0px;
		margin-top:32px;
		width:100%;

		

		.secondary {
			color:$base500;
			font-size:0.8em;
			text-align: center;
			background: white;
		}
		
		tbody {
			tr {
				transition: ease 0.2s background;
				&:hover {
					background:$base200;
				}
			}
		}
		th,td {
			padding: 8px 0px;
			border-bottom:1px solid $base200;

			span {
				font-size:0.75em;
			}
			
		}
		th {
			color:$base;
		}

		&.pricing {
			tr {
				td,th {
					font-size:14px;
					b {
						font-size:18px;
					}
				}
			}
		}
		
	}

}