@import './theme.scss';

.checkout-holder {
	border-top:1px solid $base200;
	background: linear-gradient(to bottom right, $base100, white);
	padding:30px;
	margin-left:-30px;
	margin-right:-30px;
	margin-bottom:-30px;
	margin-top:15px;
}
.checkout-holder {
	.input-group {
		display: flex;
	}
	.input-holder {
		position: relative;
		flex:1;
		&:first-child {
			margin-right:7.5px;
			padding-right:7.5px;
		}
		&:before {
			position: absolute;
			left:12.5px;
			top:10px;
			content:"";
			display: block;
			width:20px;
			height:20px;
			background-image:url(./envelope.svg);
			background-size:contain;
			opacity: 0.25;
			background-repeat: no-repeat;
			background-position: center;
		}
		&.name {
			&:before {
				background-image:url(./name.svg);
			}
		}
		&.phone {
			&:before {
				background-image:url(./phone.svg);
			}
		}
		&.company {
			&:before {
				background-image:url(./company.svg);
			}
		}
		input[type=text] {
			width:100%;
			margin-bottom:15px;
			outline:none;
			height:40px;
			padding-left:45px;
			&::placeholder {
				color:rgba($base, 0.66);
			}
			&.error {
				border:1px solid red;
			}
		}
		input.email {
			width:100%;
			margin-bottom:15px;
			outline:none;
			height:40px;
			padding-left:45px;
			&::placeholder {
				color:rgba($base, 0.66);
			}
			&.error {
				border:1px solid red;
			}
		}
	}
	input,
	.StripeElement {
		
	  height: 50px;
	  padding: 15px 12px;
	  color: $base;
	  background-color: white;
	  border: 1px solid transparent;
	  border-radius: 4px;
	  box-shadow: 0 2px 6px 0 rgba($base,0.33);
	  transition: all 0.25s ease;
	}
	
	input:focus,
	.StripeElement--focus {
	  box-shadow: 0 1px 3px 0 rgba($base,0.5);
	}
	
	.StripeElement--invalid {
	  border-color: #fa755a;
	}
	
	.StripeElement--webkit-autofill {
	  background-color: #fefde5 !important;
	}
}
