@import './theme.scss';

.payment {
	margin-left:auto;
	margin-right:auto;
	max-width:768px;
	position: relative;

	.logo {
		display: inline-block;
		height:50px;
		width:325px;
		background-image:url('./gpbook-logo.png');
		background-repeat:no-repeat;
		background-size:contain;
		background-position:center;
		margin-bottom:30px;
		
	} 

	&:after {
		content:"";
		display: block;
		position: absolute;
		left:8px;
		top:-8px;
		bottom:8px;
		right:-8px;
		background:white;
		border:1px solid $base200;
		box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.15);
		transition: ease 0.25s all;
		@media(max-width:768px){
			display: none;
		}
	}
	// &:hover {
	// 	&:after {
	// 		left:12px;
	// 		top:-12px;
	// 		bottom:12px;
	// 		right:-12px;
	// 		box-shadow: 0px 6px 24px 0px rgba(0,0,0,0.15);
	// 	}
	// 	.payment-holder {
	// 		box-shadow: 0px 12px 36px 0px rgba(151, 116, 116, 0.2);
			
	// 	}
	// }
	.payment-holder {
		background:white;
		padding:30px;
		min-width: 500px;
		border:1px solid $base200;
		margin-top:90px;
		position: relative;
		z-index: 100;
		box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.15);
		transition: ease 0.25s all;

		@media(max-width:768px){
			margin-top:0px;
			padding:15px;
		}
	}
}