@import './theme.scss';

html {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
  }
*, *:before, *:after {
	-webkit-box-sizing: inherit;
	-moz-box-sizing: inherit;
	box-sizing: inherit;
}
body {
	margin:0;
	padding:0;
	font-family: 'Nunito Sans', sans-serif;
	text-rendering: optimizeLegibility;
	color:#36474f;
	background:#F3F6F7;
	overflow-x:hidden;
}
h1 {
	margin-top:0;
}
h3 {
	margin-bottom:0.25em;
	margin-top:1.4em;
}

hr {
	border:none;
	background-color:$base200;
	height:1px;
}

.btn-add {
	display:inline-block;
	padding:0.5em 1.5em;
	color:$base;
	border:1px solid $base200;
	font-weight:600;
	font-size:14px;
	cursor: pointer;
	&:hover {
		background:$base200;
		color:$base;
	}
}

.btn-pay {
	display:inline-block;
	padding:0.5em 1.5em;
	color:white;
	background:$primary;
	font-weight:600;
	font-size:20px;
	margin-top:15px;
	cursor: pointer;
	&:hover {
		background:$primary600;
	}
	&.processing {
		color:$base;
		background:$base200;
		cursor: initial;
	}
	&.success {
		color:white;
		background:$base500;
		cursor: initial;

	}
	&.error {
		color:white;
		background:darkred;
		cursor: initial;

	}
}

.doctors-table {
	width:100%;
	border-collapse: collapse;
	margin-top:15px;
	margin-bottom:15px;
	@media(max-width:768px){
		overflow: scroll;
	}
	thead {
		tr {
			td {
				// padding:0px 0px;
			}
		}
	}
	tbody {
		tr {
			td {
				border-bottom:1px solid $base200;
				padding:8px 0px;
			}
		}
	}
	.name {
		font-size:18px;
		padding-left:10px;
		font-weight: 600;
	}
	.check {
		width:20px;
	}
	.status {
		width:100px;
	}
	.price {
		width:100px;
	}
	.btn-remove {
		font-size:14px;
		// background:$base200;
		display:inline-block;
		padding:2px 6px;
		font-size:14px;
		color:$base;
		cursor:pointer;
		&:hover {
			background:darkred;
			color:white;
		}
	}
	
}

.label {
	background:$base200;
	display:inline-block;
	padding:4px 8px;
	border-radius:1em;
	font-size:14px;
	color:$base;
	font-weight:500;
	
	text-align: center;
	&.label-warning {
		min-width:64px;
		color:darkred;
		background:rgba(red,0.1);
		// background:none;
	}
	&.label-success {
		min-width:64px;
		color:darkgreen;
		background:rgba(green,0.1);
		// background:none;
	}
}
.icon {
	background:$base200;
	display:inline-block;
	padding:4px 8px;
	border-radius:1em;
	font-size:14px;
	color:$base;
	cursor: pointer;
	font-weight:500;
	float:right;
	&.bottom {
		float:none;
		margin-top:15px;
		font-size:18px;
		padding:0.5em 1.5em;
		&.processing {
			color:darkblue;
			background:rgba(blue,0.1);
		}
		&.success {
			color:darkgreen;
			background:rgba(green,0.1);
		}
		&.error {
			color:darkred;
			background:rgba(red,0.1);
		}
	}
	
}
.redirected {
	.clinician {
		display:flex;
		margin-top:4px;
		align-items: center;
		padding:4px;
		border-radius:4px;
		text-decoration: none;
		color:#000;
		&:hover {
			background:#efefef;
			cursor:pointer;
		}
		.avatar {
			border-radius:4px;
			width:48px;
			height:48px;
			background-size:48px 48px;
			background-position:center;
			background-color:#e1e1e1;
			background-image:url(./doctor-male.svg);
			&.Female {
				background-image:url(./doctor-female.svg);
			}
		}
		.details {
			padding-left:8px;
			.name {
				font-size:18px;
				font-weight:600;
			}
		}
	}
	
	input[type=text] {
		border-radius:1em;
		padding:8px 10px;
		padding-left:15px;
		width:66%;
		background:$base200;
		border:1px solid transparent;
		outline:none;
		&::placeholder {
			color:rgba($base, 0.66);
		}
		&:hover,&:focus {
			background:lighten($base200,5%);
			border:1px solid $base200;
		}
	}
}
.doctors-table {
	input[type=text] {
		margin-left:-10px;
		margin-top:-2px;
		margin-bottom:-2px;
		border-radius:1em;
		padding:4px 10px;
		padding-left:15px;
		width:66%;
		background:$base100;
		border:none;
		outline:none;
		&::placeholder {
			color:rgba($base, 0.66);
		}
		&:hover,&:focus {
			background:$base200;
		}
	}
}

//   input[type=checkbox] {
// 	display:none;
//   }
//   .slide-toggle {
// 	  display: block;
// 	  position: relative;
// 	  flex: none;
// 	  width: 50px;
// 	  height: 30px;
// 	  border-radius: 30px;
// 	  background-color: #d7d2cb;
// 	  cursor: pointer;
// 	  transition: all 0.1s ease-in-out;
// 	  z-index: 1;
  
// 	  &::before,
// 	  &::after {
// 		  content: ' ';
// 		  display: block;
// 		  position: absolute;
// 		  top: 1px;
// 		  border-radius: 30px;
// 		  height: 28px;
// 		  background-color: #fff;
// 		  transform: translate3d(0,0,0);
// 		  transition: 0.2s cubic-bezier(0, 1.1, 1, 1.1);;
// 	  }
  
// 	  &::before {
// 		  z-index: -1;
// 		  width: 48px;
// 		  right: 1px;
// 		  transform: scale(1);
// 	  }
  
// 	  &::after {
// 		  z-index: 1;
// 		  width: 28px;
// 		  left: 1px;
// 		  box-shadow: 0 1px 4px 0.5px rgba(0, 0, 0, 0.25);
// 	  }
  
// 	  input:checked + & {
// 		  background-color: $primary;
  
// 		  &::before {
// 			  transform: scale(0);
// 		  }
  
// 		  &::after {
// 			  transform: translate3d(20px,0,0);
// 		  }
// 	  }
//   }
  

/* base styles */
input[type="radio"], 
input[type="checkbox"] {
    height: 15px;
    width: 15px;
    vertical-align: middle;
    margin: 0 5px 5px 0;
    border: 1px solid #A5A5A5;
    background: -webkit-linear-gradient(#FCFCFC, #DADADA);
    -webkit-border-radius: 8px;
    border-radius: 8px;
    -webkit-appearance: none;
    -webkit-transition: box-shadow 200ms;
}

/* inset shadows */
input[type="radio"]:not(:disabled), 
input[type="checkbox"]:not(:disabled) {
    box-shadow:inset 1px 1px 0 #fff, 0 1px 1px rgba(0,0,0,0.1);
}

/* decrease border radius for checkbox */
input[type="checkbox"] {
    -webkit-border-radius:2px;
    border-radius:2px;
}

/* hover state */
input[type="radio"]:not(:disabled):hover,
input[type="checkbox"]:not(:disabled):hover {
    border-color:rgba(0,0,0,0.4);
    box-shadow:inset 1px 1px 0 #fff, 0 0 4px rgba(0,0,0,0.2);
}

/* active state */
input[type="radio"]:active:not(:disabled),
input[type="checkbox"]:active:not(:disabled) {
    background-image: -webkit-linear-gradient(#C2C2C2, #EFEFEF);
    box-shadow:inset 1px 1px 0 rgba(0,0,0,0.2), inset -1px -1px 0 rgba(255,255,255,0.6);
    border-color:#707070;
}



/* input checked border color */
input[type="radio"]:checked,
input[type="checkbox"]:checked {
    border-color:#707070;
}

/* radio checked bg */
input[type="radio"]:checked,
input[type="radio"]:checked:active {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAMAAADXT/YiAAAAVFBMVEX///9eXl5RUVEzMzMbGxszMzNRUVEiIiJRUVFFRUUzMzMiIiJFRUUzMzNRUVFRUVE6OjorKysiIiJRUVFFRUU6OjozMzMrKytRUVFFRUUzMzMrKyvGHNe0AAAAHHRSTlMAERERESJmZnd3d3eIiJmqqqqqzMzMzMzd3d3dIlELBQAAAEFJREFUCNdjYEACLOxC3CwgBrOgtLi4ICuQxSUlBgS8QJaAKAgIAFmckiIiIpI8QBYTn4SwBD8TSAsjGz8HI7JhAH1fArjBaKLrAAAAAElFTkSuQmCC") center no-repeat, -webkit-linear-gradient(#FCFCFC, #DFDFDF);
}

/* checkbox checked bg */
input[type="checkbox"]:checked,
input[type="checkbox"]:checked:active {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAMAAADXT/YiAAAAOVBMVEX///8zMzMqKipCQkI8PDwzMzNISEhISEhISEhCQkIqKio8PDwzMzMqKipISEhCQkI8PDwzMzMqKipFad0NAAAAE3RSTlMAIiIzMzNEZnd3qru7u8zMzMzMuKlnZwAAADhJREFUCNdjYIACJhjNJQRl8ApBWIw8goKsDDw8UAaDgAA3t4AAC1CGkx8ImMGKOPj42KAmsEMYAEUyAa2GdxXhAAAAAElFTkSuQmCC") center no-repeat, -webkit-linear-gradient(#FCFCFC, #DFDFDF);
}

/* disabled input */
input:disabled {
    opacity:.6;
    border-color:#aaa;
    box-shadow:0;
}

/* style label for disabled input */
input:disabled + label {
    color:#666;
    cursor:default;
    -webkit-user-select: none;
}